module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cloud8-TestEnv","short_name":"Cloud8-TestEnv","start_url":"/","background_color":"#fff","theme_color":"#7E22D6","display":"standalone","icon":"static/logos/cloud8-testenv.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
